/*eslint sort-keys: ["error", "asc", {caseSensitive: false, natural: true}] -- expect object keys to be sorted*/
/*eslint typescript-sort-keys/interface: ["error", "asc", {"caseSensitive": false, "natural": true,}] -- expect interface keys to be sorted*/

import {
  Literal,
  Number,
  Record as RRecord,
  Runtype,
  Static,
  String,
  Undefined,
  Union,
} from "runtypes";

import {
  NoPrewarmedImages,
  PrewarmedDefaultInfo,
} from "./customKernels/customKernelTypes";
import { DataConnectionType } from "./dataConnectionTypes.js";
import { StringDate } from "./datetimeType.js";
import { MagicTypeAheadProvider } from "./enums.js";
import { ProductionEnvironment } from "./environment";
import { OrgId } from "./idTypeBrands";
import {
  DefaultKernelManagementCPMigrationState,
  KernelManagementCPMigrationState,
} from "./kernel";
import {
  FlaggedNewUserFeature,
  FlaggedNewUserFeatureSet,
  NewUserFeatureFlagDefaults,
  isNewUserFeature,
} from "./NewUserFeatures.js";
import { PermissionedEntityTypeName } from "./PermissionedEntityTypeName.js";
import { typedObjectKeys } from "./utils";

export type StringArrayJson = {
  values: string[];
};

export type RateLimitConfig = {
  batchSize: number;
  duration: number;
  points: number;
};

export type ElasticsearchReindexConfig = {
  initialIdOffset: {
    columns?: string;
    databases?: string;
    schemas?: string;
    tables?: string;
  };
};

export type ElasticsearchIndexReaderConfig = {
  datasourceSchemas: string;
  projects: string;
};
type PerIndexConfig = {
  backfillIndexes: string[];
  // deprecated - use ElasticsearchIndexReaderConfig
  readerIndex: string;
  writerIndexes: string[];
};
export type ElasticsearchIndexConfig = {
  datasourceSchemas: PerIndexConfig;
  projects: PerIndexConfig;
};
export const HexToESSyncMode = {
  ALWAYS_COMMIT: "always_commit",
  COMMIT_ONLY: "commit_only",
  DISABLED: "disabled",
  ENABLED: "enabled",
};
export type HexToESSyncMode =
  (typeof HexToESSyncMode)[keyof typeof HexToESSyncMode];
export type QueryConfig = {
  creator: {
    weight: number;
  };
  description: {
    boost?: number | null;
  };
  last_updated_ts: {
    decay: number;
    offset: string;
    scale: string;
  };
  title: {
    boost?: number | null;
  };
};
export const DEFAULT_QUERY_CONFIG: QueryConfig = {
  creator: {
    weight: 2,
  },
  description: {
    boost: null,
  },
  last_updated_ts: {
    decay: 0.2,
    offset: "14d",
    scale: "90d",
  },
  title: {
    boost: null,
  },
};

export type ComputeHeartbeatSyncToStripeStatus =
  | "JOB_DISABLED" // this queue will not run
  | "PROCESS_BUT_DONT_SEND" // the queue will run to mark compute heartbeats as seen, but none will be sent to stripe
  | "SEND_TO_STRIPE"; // the queue will run and send appropriate compute heartbeats to stripe

/**
 * The config for the internal test collection queue that we use to run headless notebooks that are
 * part of special collections on a scheduled cadence.
 */
export type InternalTestCollectionQueue = {
  /**
   * The collection ids we want to run tests on.
   */
  collection_ids: string[];
  /**
   * Whether or not the internal test queue is enabled; including here so that we can disable outside of server restarts.
   */
  enabled: boolean;
  /**
   * How long each project run should have before we declare the run to take too long and kill it manually.
   */
  single_run_timeout_ms: number;
  /**
   * Settings for slack notifications based on queue success and failure.
   */
  slack_settings: {
    /**
     * The ID of the default slack channel to report success and failure messages to.
     * This can be overridden based on collection_id by using the overrides field.
     */
    default_slack_channel_id: string;
    /**
     * Overrides Slack behavior for a particular collectionId.
     */
    overrides: {
      [collectionId: string]: {
        send_msg_on_success: boolean;
        slack_channel_id: string;
        tag_support_on_failure: boolean;
      };
    };
    /**
     * Whether or not we should send a slack message if the collection succeeds.
     */
    send_slack_on_success: boolean;
  };
};
export const DEFAULT_INTERNAL_TEST_COLLECTION_QUEUE = {
  collection_ids: [],
  cron: "* 0 0 0 0",
  enabled: false,
  single_run_timeout_ms: 120_000,
  slack_settings: {
    default_slack_channel_id: "",
    overrides: {},
    send_slack_on_success: false,
  },
};

export interface ClientDevtoolsFlagConfig {
  app_session_diff_checker: boolean;
  cell_debugger: boolean;
  chart_debugger: boolean;
  explore_debugger: boolean;
  external_profiler_user: boolean;
  internal_devtools_enabled: boolean;
  magic_debugger: boolean;
  multiplayer_debugger: boolean;
  python_error_reader: boolean;
  something_else: boolean;
}

const DEFAULT_CLIENT_DEVTOOLS_FLAG_CONFIG: ClientDevtoolsFlagConfig = {
  app_session_diff_checker: false,
  cell_debugger: false,
  chart_debugger: false,
  explore_debugger: false,
  external_profiler_user: false,
  internal_devtools_enabled: false,
  magic_debugger: false,
  multiplayer_debugger: false,
  python_error_reader: false,
  something_else: false,
};

export type BackfillQueryConfig = {
  queries: {
    batch_count: string;
    table: string;
  }[];
};

export const DEFAULT_BACKFILL_CONFIG = {
  queries: [],
};

export type WebsocketAuthCachingConfig = {
  timeout: number | null;
};

export type WebsocketDegradedNetworkConfig = {
  fastForwardWindowSeconds: number;
  keepAliveSeconds: number;
};

export type SemanticCapsPerEntityConfig = {
  [Entity in PermissionedEntityTypeName | "ENABLE_ALL_ENTITIES"]?: boolean;
};

// This LD flag represents whether or not we should use SemanticCaps auth checks for the entity type.
// The default config is an empty dictionary, meaning all SemanticCaps are disabled for each entity.
export const DEFAULT_SEMANTIC_CAPS_PER_ENTITY_CONFIG: SemanticCapsPerEntityConfig =
  {};

export type DataSourceSchemaSleeperConfig = {
  sleepEvery: number;
  sleepForMs: number;
};

export type AuditLogWorkOSBackfillConfig = {
  config?: {
    dry_run: boolean;
    end_time: number;
    event_type_filter?: string[];
    orgIds?: string[];
    start_time: number;
  };
  enabled: boolean;
};

export type SchemaRefreshRateLimitConfig = {
  enabled: boolean;
  globalLimit: number;
  orgLimit: number;
};

const DEFAULT_SCHEMA_REFRESH_RATE_LIMIT_CONFIG: SchemaRefreshRateLimitConfig = {
  enabled: false,
  globalLimit: 10,
  orgLimit: 1,
};

export const CustomGraphqlObjectAuthzCheckType = {
  LOG_ONLY: "log_only",
  REQUIRE: "require",
  SKIP: "skip",
};
export type CustomGraphqlObjectAuthzCheckType =
  (typeof CustomGraphqlObjectAuthzCheckType)[keyof typeof CustomGraphqlObjectAuthzCheckType];

export type DisableSingleGraphqlObjectAuthz = {
  [key: string]: "DISABLE";
};

export type DisableProjectFiltersByType = {
  [key: string]: "DISABLE" | "ENABLE";
};

export type SchemaRefreshInterruptType =
  | "NO_INTERRUPT"
  | "PAUSE"
  | "DISRUPTIVE_CANCEL";

export const IdleKernelExpirySettings = RRecord({
  cronSchedule: Union(String, Undefined),
  gracePeriodMs: Union(Number, Undefined),
  limit: Union(Number, Undefined),
});

export type IdleKernelExpirySettings = Static<typeof IdleKernelExpirySettings>;

export const defaultIdleKernelExpirySettings = {
  cronSchedule: "*/1 * * * *",
  gracePeriodMs: 30 * 1000,
  limit: 100,
} satisfies IdleKernelExpirySettings;

export const LanceSyncQueueLimiterSettings = RRecord({
  duration: Number,
  max: Number,
});

export type LanceSyncQueueLimiterSettings = Static<
  typeof LanceSyncQueueLimiterSettings
>;

export const defaultLanceSyncQueueLimiterSettings = {
  duration: 10_000, // Lance timeout is 10 seconds
  max: 10, // Our minimum number of pods is 10
} satisfies LanceSyncQueueLimiterSettings;

export interface PublicApiBanConfig {
  isBanned: boolean;
}

const ReduceDefaultLogicIdleTimeoutFlag = RRecord({
  reduceDefaultTimeoutAfter: StringDate,
});

export type ReduceDefaultLogicIdleTimeoutFlag = Static<
  typeof ReduceDefaultLogicIdleTimeoutFlag
>;

export function parseReduceDefaultLogicIdleTimeoutFlag(
  obj: Record<string, unknown>,
): ReduceDefaultLogicIdleTimeoutFlag | null {
  try {
    return ReduceDefaultLogicIdleTimeoutFlag.check(obj);
  } catch (_e) {
    return null;
  }
}

export const SemanticIngestEsRateLimitConfig = RRecord({
  duration: Number,
  points: Number,
});
export type SemanticIngestEsRateLimitConfig = Static<
  typeof SemanticIngestEsRateLimitConfig
>;

// String literal type in case we want to add future sentinel values
export type RudderstackFeatureFlag = "ON" | "OFF";

export interface FeatureFlagSet extends FlaggedNewUserFeatureSet {
  "additional-base-images": boolean;
  "additional-data-connection-refresh-logging": boolean;
  "additional-mariadb-params": boolean;
  "allow-duplication-for-project": boolean;
  "allow-embed-create-schedules": boolean;
  "allow-embedded-user": boolean;
  "allow-public-domain-signups": boolean;
  "anthropic-tool-use": "tool" | "any" | "auto";
  "app-freshness-no-never": boolean;
  // experiment flag to be configured at 50% by app session
  "app-session-a-a": boolean;
  "app-session-garbage-collector-cron": string;
  "app-session-garbage-collector-limit": number;
  "app-session-output-garbage-collector-limit": number;
  "app-state-hydration": boolean;
  "app-state-persistence": boolean;
  "audit-log-workos-backfill": AuditLogWorkOSBackfillConfig;
  "audit-log-workos-backfill-interrupt": boolean;
  "auto-data-labels-for-lines": boolean;
  "auto-rebuild-custom-images-delay": number;
  "auto-rebuild-docker-images": boolean;
  "auto-rebuild-docker-images-cron-job": boolean;
  "auto-refresh-client-config": Record<string, unknown>;
  "auto-reload-client-on-auth-fail": boolean;
  "auto-rerun-app-period-seconds": number;
  "avoid-table-store-multi": boolean;
  "bigframes-dataframes": boolean;
  "bigquery-additional-project-ids": boolean;
  "bigquery-oauth": boolean;
  "bring-your-own-repo-ui": boolean;
  "bulk-deactivate-users-by-domain": boolean;
  "cast-string-columns-for-filters": boolean;
  "cell-debugger": boolean;
  "cell-only-screenshot": boolean;
  "chain-sql-engine": "legacy" | "sqlparse" | "sqlglot";
  "chart-debugger": boolean;
  "chart-reference-lines": boolean;
  "chart-v1": boolean;
  "chart-visual-filtering-migration-v2": boolean;
  "chart-warnings": boolean;
  "check-kernel-limits": boolean;
  "client-devtools": ClientDevtoolsFlagConfig;
  "component-references-v-2": boolean;
  "component-upgrade-notifications": boolean;
  "compute-heartbeat-send-to-segment-enabled": boolean;
  "compute-heartbeat-sync-to-stripe-cron-schedule": string;
  "compute-heartbeat-sync-to-stripe-mode": string;
  "compute-heartbeat-sync-to-stripe-status": ComputeHeartbeatSyncToStripeStatus;
  "consolidated-kernel-initialization": boolean;
  "cp-diff-tracking-cron-schedule": string;
  "cp-diff-tracking-delay-threshold-ms": number;
  "cp-diff-tracking-enabled": boolean;
  "cp-kernel-management": KernelManagementCPMigrationState;
  "cryptominer-regex": string;
  "custom-kernels-new-auto-rebuild-logic": boolean;
  "custom-kernels-ui": boolean;
  "custom-kernels-ui-v2": boolean;
  "data-conn-diff-schema-update": boolean;
  "data-conn-diff-schema-update-threshold": number;
  "data-conn-insert-chunk-size": number;
  "data-conn-sleep-times": DataSourceSchemaSleeperConfig;
  "data-conn-tree-schema": boolean;
  "data-connection-db2-setup": boolean;
  "data-connection-types-to-limit": Partial<
    Record<DataConnectionType | "ssh", number>
  >;
  "data-curation-tab": boolean;
  "data-retention-available": boolean;
  "data-retention-enable-outputs": boolean;
  "data-retention-enable-query-cache": boolean;
  "data-source-schema-arrow": boolean;
  "database-backfill-cron": string;
  "database-backfill-queries": BackfillQueryConfig;
  "database-query-permissions": boolean;
  "databricks-jdbc-upgrade": boolean;
  "databricks-oauth": boolean;
  "dataservice-execution-events": boolean;
  "db-prompt-templates": boolean;
  "dbt-data-conn-sleep-times": DataSourceSchemaSleeperConfig;
  "dbt-disallow-proxy-server": boolean;
  "dbt-fetch-batch-size": number;
  "dbt-timeout-metadata": number;
  "dc-schema-filter-ui": boolean;
  "deadlock-detector-timeout-s": number;
  "debug-gh-sync": boolean;
  "debug-publish-preview": boolean;
  "declutter-data-browser": boolean;
  "default-display-table": boolean;
  "delete-app-session-api-client-on-kill": boolean;
  "disable-ao-garbage-collector": boolean;
  "disable-app-session-garbage-queue": boolean;
  "disable-chat-support-for-company-closure": {
    available: boolean;
    cta: string;
    message: string;
  };
  "disable-execution-event-api": boolean;
  "disable-execution-event-writes": boolean;
  "disable-hex-garbage-collector": boolean;
  "disable-hex-version-default-app-session-garbage-collector": boolean;
  "disable-large-pivot-csv": boolean;
  "disable-prerun-app": boolean;
  "disable-publish-column-profiling": boolean;
  "disable-table-deletion-queue": boolean;
  "disable-workspace-sharing": boolean;
  "display-table-empty-state": boolean;
  "do-not-charge-for-kernels-created-by-hex": boolean;
  "duckdb-charts": boolean;
  "email-denials": boolean;
  "embedding-v0": boolean;
  "emit-dbt-data-size-metric": boolean;
  "emit-schema-size-metric": boolean;
  "enable-ao-partial-auth-filtering": boolean;
  "enable-csv-exports-scheduled-runs": boolean;
  "enable-delete-pods-from-wiz-issues": boolean;
  "enable-oauth-schema-auth": boolean;
  "enable-quick-search": boolean;
  "enable-reindex-schema": boolean;
  "enable-run-project-with-trace": boolean;
  "enable-schema-refresh-download-grants": boolean;
  "enable-shadow-kernel-executions": boolean;
  "enable-sql-completion-logs": boolean;
  "enable-write-schema-to-es": boolean;
  "enforce-compute-spend-limits": boolean;
  "es-bulk-delete-chunk-size": number;
  "es-bulk-timeout-schema-refresh": string;
  "es-bulk-write-chunk-size": number;
  "es-delete-all-datasources-delay-ms": number;
  "es-delete-by-query-timeout": string;
  "es-index-config": ElasticsearchIndexConfig;
  "es-index-reader-config": ElasticsearchIndexReaderConfig;
  "es-reindex-config": ElasticsearchReindexConfig;
  "es-sync-batch-size": number;
  "es-sync-hex-chunk-size": number;
  "es-sync-hex-job-limit": number;
  "experimentation-test": boolean;
  "explore-agent-response": boolean;
  "explore-cancel-button": boolean;
  "explore-cell-output": boolean;
  "explore-debug-outputs": boolean;
  "explore-large-data-warning": number;
  "explore-suggested-filters": boolean;
  "explore-whnc-dataframe": boolean;
  // If enabled, Explorer role is visible and can be assigned to users
  "explorer-role": boolean;
  // If enabled, UI references "Can explore" instead of "Can view"
  // and "Can view" instead of "App only"
  "explorer-role-can-view-change": boolean;
  // If enabled, Explorer role is visible in the UI with  a "Contact Sales" CTA
  "explorer-role-visible": boolean;
  "external-file-integrations": boolean;
  "facet-chart": boolean;
  "force-redefinition-edges": boolean;
  "fstr-timeout-ms": number;
  "git-package-import-v2": boolean;
  // TODO we can coalesce grant-access-for-project-from-slack with slack-interactivity
  "grant-access-for-project-from-slack": boolean;
  "graphview-update": boolean;
  "hard-rowlimit": boolean;
  "hashed-bearer-tokens": boolean;
  "hex-to-es-sync-mode": HexToESSyncMode;
  "hide-preview-table": boolean;
  "hybrid-execution": boolean;
  "hybrid-vega-renderer": boolean;
  "hydrate-explore-cell": boolean;
  "idle-kernel-expiry-settings": IdleKernelExpirySettings;
  "in-app-announcements": Record<string, unknown>[];
  "in-app-releases": Record<string, unknown>;
  "in-app-whats-new": Record<string, unknown>;
  "indirect-query-event-aggregation": boolean;
  "intercept-browser-find": boolean;
  "internal-only-hex-sl-ingest": boolean;
  "internal-test-collection-queue": InternalTestCollectionQueue;
  // Install `transform` python package after upgrading to Pandas 1.5
  "kernel-lifetime-stats": boolean;
  "kernel-metric-logger": boolean;
  "kernel-metric-logger-code": boolean;
  "lance_db-sync-limiter-settings": Record<string, unknown>;
  "lance-db-admin-only-backfill-ui": boolean;
  "lance-db-asset-garbage-collector": boolean;
  "lance-db-asset-garbage-collector-limit": number;
  "lance-db-asset-garbage-collector-queue-frequency-ms": number;
  "lance-db-data-conn-backfill-data-conn-to-skip": string[];
  "lance-db-data-conn-backfill-frequency-ms": number;
  "lance-db-data-conn-backfill-limit": number;
  "lance-db-data-conn-backfill-retry-limit": number;
  "lance-db-data-conn-errored-actions-limit": number;
  "lance-db-data-conn-stuck-in-backfilling-limit": number;
  "lance-db-entity-promotion-limit": number;
  "lance-db-hex-backfill": boolean;
  "lance-db-hex-backfill-excluded-orgs": string[];
  "lance-db-hex-backfill-frequency-ms": number;
  "lance-db-hex-backfill-limit": number;
  "lance-db-hex-errored-actions-limit": number;
  "lance-db-hex-stuck-in-backfilling-limit": number;
  "lance-db-hex-stuck-in-processing-limit": number;
  "lance-db-long-running-action-threshold-hours": number;
  "lance-db-sync-batch-lance-actions-queue-interval-ms": number;
  "lance-db-sync-batch-size": number;
  "lance-db-sync-on-data-conn-refresh": boolean;
  "lance-db-sync-on-data-manager-column-action": boolean;
  "lance-db-sync-on-data-manager-data-conn-action": boolean;
  "lance-db-sync-on-data-manager-database-action": boolean;
  "lance-db-sync-on-data-manager-schema-action": boolean;
  "lance-db-sync-on-data-manager-table-action": boolean;
  "lance-db-table-retrieval-limit": number;
  "lance-db-table-retrieval-minimum-result-size": number;
  "lance-db-table-retrieval-timeout-ms": number;
  "lance-db-un-indexed-rows-threshold": number;
  "lance-db-use-actions-v2": boolean;
  "lance-db-use-flow-producer": boolean;
  "lance-db-where-batch-size": number;
  "lance-db-write-timeout-ms": number;
  "lazy-load-results": boolean;
  "lazy-load-results-bq": boolean;
  "legacy-api-access": boolean;
  "legacy-git-sync": boolean;
  "log-dbt-metadata-counts": boolean;
  "logic-session-timeout-extension": boolean;
  "magic-35-autofix": boolean;
  "magic-cell-navigation": boolean;
  "magic-charts-v2": boolean;
  "magic-deflection": boolean;
  "magic-deflection-cell-limit": number;
  "magic-deflection-embed": boolean;
  "magic-diff-based-editing-threshold": string;
  "magic-diff-based-editing-threshold-sql": string;
  "magic-disable-backtick-stripping": boolean;
  "magic-edit-sql-formatting-disabled": boolean;
  "magic-enabled": boolean;
  "magic-example-table-prompts": number;
  "magic-example-table-prompts-query-threshold": number;
  "magic-explore": boolean;
  "magic-explore-edit": boolean;
  "magic-explore-edit-logic-view": boolean;
  "magic-explore-output-contents": boolean;
  "magic-inline-completion-code": boolean; // DEPRECATED
  "magic-inline-completion-sql": boolean; // DEPRECATED
  "magic-lance-project-retrieval-limit": number;
  "magic-lance-project-retrieval-vector-distance-threshold": number;
  "magic-max-other-dfs": number;
  "magic-mention-details": boolean;
  "magic-run-edits": boolean;
  "magic-show-tr": boolean;
  "magic-smoke-test-single-cell": boolean;
  "magic-suggested-endorsements": boolean;
  "magic-suggested-prompts-max-attempts": number;
  "magic-suggested-prompts-pd-events-limit": number;
  "magic-suggested-prompts-pd-limit": number;
  "magic-summary": boolean;
  "magic-table-retrieval-columns-per-table-limit": number;
  "magic-table-retrieval-desc-similarity-threshold": number;
  "magic-typeahead-provider-code": MagicTypeAheadProvider;
  "magic-typeahead-provider-md": MagicTypeAheadProvider;
  "magic-typeahead-provider-sql": MagicTypeAheadProvider;
  "magic-update-suggested-prompts-on-schema-refresh": boolean;
  "manual-work-os-syncs": boolean;
  "map-dataset-layer": boolean;
  "map-us-county-data": boolean;
  "mark-orgs-from-wiz-issues": boolean;
  "max-attempts-wait-for-es-task-completion": number;
  "max-dynamic-list-size": number;
  "max-fstr-results": number;
  "max-kernels-per-user": number;
  "max-signup-limit": number;
  "max-untrusted-kernels": number;
  "maybe-embed-hex-delay-ms": number;
  "minerva-executor-ui": boolean;
  "minerva-selective-execution-cell-types": string[];
  "mini-hex-chart": boolean;
  "mysql-aurora-property": boolean;
  "new-metricflow-ingest": boolean;
  "new-semantic-dataset-ui": boolean;
  "new-share-dialog": boolean;
  "new-toc": boolean;
  "no-code-announce-blog-post-url": string;
  "no-code-announce-dialog": boolean;
  "no-code-announce-video-url": string;
  "no-max-signups": boolean;
  "non-vf-legend": boolean;
  "oauth-collab-always-expose-outputs": boolean;
  "oauth-collab-lite": boolean;
  "oauth-dc-service-account-schema": boolean;
  "only-add-code-cells-on-first-run": boolean;
  "open-ai-embedding-batch-size": number;
  "open-ai-embedding-concurrency": number;
  "open-ai-model-override": string;
  "outline-v2": boolean;
  "outline-v2-live-toc": boolean;
  "output-iframe-domain":
    | "blob"
    | "subdomain-per-tab"
    | "subdomain-per-cell"
    | "global-subdomain";
  "pandas-copy-on-write": boolean;
  "password-users": boolean;
  "patch-charts": boolean;
  "preview-mode": boolean;
  "prewarm-kernels-ux-enablement": boolean;
  "priority-cell-execution": boolean;
  "product-launch": boolean;
  "product-launch-demo-hide-beta-flags": boolean;
  "prompt-injection-window-size": number;
  "prune-sql-output-stages": boolean;
  "public-api-ban-config": PublicApiBanConfig;
  "publish-as-snapshot": boolean;
  "pylon-chat": boolean;
  "query-event-queue-enabled": boolean;
  "query-mode-default-on": boolean;
  "query-table-in-no-code-cell": boolean;
  "quick-search-query-config": QueryConfig;
  "redis-presence-enabled": boolean;
  "redis-presence-recording-disabled": boolean;
  "reduce-csp-after-domain-change": boolean;
  "reduce-default-logic-idle-timeout-datetime": Record<string, unknown>;
  "refresh-on-graphql-error": boolean;
  "refresh-version-on-reconnect": boolean;
  "register-ready-pods": boolean;
  "reindex-es-batch-size": number;
  "reindex-es-job-limit": number;
  "reindex-schema-rate-limit-duration": number;
  "reindex-schema-rate-limit-points": number;
  "resource-sync-to-compute-heartbeat-enabled": boolean;
  "rudderstack-migration": RudderstackFeatureFlag;
  "saved-views": boolean;
  "saved-views-schedules-fe": boolean;
  "scheduled-run-concurrency": number;
  "scheduled-run-delay": number;
  "scheduler-delayed-job-queue-v2": boolean;
  "schema-chunk-size": number;
  "schema-refresh-data-service-batch-size": number;
  "schema-refresh-download-grants-rate-limit-config": RateLimitConfig;
  "schema-refresh-download-schema-batch-size": number;
  "schema-refresh-gc-batch-size": number;
  "schema-refresh-gc-delay-ms": number;
  "schema-refresh-get-orphaned-entities-batch-size": number;
  "schema-refresh-get-schema-batch-size": number;
  "schema-refresh-interrupt": SchemaRefreshInterruptType;
  "schema-refresh-max-column-count": number;
  "schema-refresh-rate-limit-duration": number;
  "schema-refresh-rate-limit-points": number;
  "schema-refresh-update-db-batch-size": number;
  "schema-refresh-update-es-batch-size": number;
  "schema-refresh-update-lance-batch-size": number;
  "schema-refresh-use-get-datasource-entity-endpoints": boolean;
  "schema-refresh-v3-rate-limiting": SchemaRefreshRateLimitConfig;
  "screenshot-device-scale-factor": number;
  "screenshot-extra-timeout": number;
  "screenshot-max-height": number;
  "selective-minerva-execution": boolean;
  "semantic-caps-per-entity": SemanticCapsPerEntityConfig;
  "semantic-ingest-es-rate-limit-duration": SemanticIngestEsRateLimitConfig;
  "semantic-layer": boolean;
  "server-open-ai-model-override": string;
  "show-compute-profile-migration-alerts": boolean;
  "sidecar-batch-debounce-ms": number;
  "sidecar-health-monitoring-interval": number;
  "signed-embedding-api-rate-limit": number;
  "skip-old-sidecar-requests": boolean;
  "slack-channel-query-limit": number;
  "slack-integration-v2": boolean;
  "slack-interactivity": boolean;
  "smoke-test-cleanup-limit": number;
  "sqlfluff-format": boolean;
  "st-prewarmed-defaults": PrewarmedDefaultInfo;
  "store-embedded-api-calls": boolean;
  "stream-motherduck-results": boolean;
  "stripe-check-events-minimum-ms": number;
  "stripe-local-data": boolean;
  "stytch-telemetry-v1": boolean;
  "supernova-a-a": boolean;
  "svc-aggs": boolean;
  "svg-chart": boolean;
  "table-store-hex-versioning-enabled": boolean;
  /**
   * Disabled in local environments with an override by default. Please consume
   * via `useIsTelemetryEnabled` and `getIsTelemetryEnabled` in the client and
   * server, respectively.
   *
   * See docstring in Config type:
   * https://github.com/hex-inc/hex/blob/master/packages/server/src/config.ts
   */
  "telemetry-enabled": boolean;
  "telemetry-timeout": number;
  "telemetry-truncate": boolean;
  "telemetry-use-stytch-cname": boolean;
  "text-cell-always-stream-mp-updates": boolean;
  "totals-bar": boolean;
  "truncate-execution-event": boolean;
  "typeahead-a-a-experiment": boolean;
  "unidf-query-mode": boolean;
  "upgrade-duckdb": boolean;
  "upgrade-snowpark": boolean;
  "use-create-database-v2": boolean;
  "use-custom-kernels-only": boolean;
  "use-in-house-ck": boolean;
  "use-new-hv-default-app-session": boolean;
  "use-only-register-ready-pods": boolean;
  "viewer-logic-perm-warning": {
    changelogName: string;
    dateString: string;
    on: boolean;
  };
  "virtualized-diff": boolean;
  "wait-for-es-task-completion-delay-in-ms": number;
  "websocket-auth-caching": WebsocketAuthCachingConfig;
  "websocket-degraded-network-config": WebsocketDegradedNetworkConfig;
  "workos-backfill-enabled": boolean;
  "workspace-api-tokens": boolean;
  "workspace-invite-links": boolean;
  "zendesk-chat": boolean;
}

export type FeatureFlag = keyof FeatureFlagSet;

export const DEFAULT_FLAGS: FeatureFlagSet = {
  "additional-base-images": false,
  "additional-data-connection-refresh-logging": false,
  "additional-mariadb-params": false,
  "allow-duplication-for-project": false,
  "allow-embed-create-schedules": false,
  "allow-embedded-user": false,
  "allow-public-domain-signups": false,
  "anthropic-tool-use": "tool",
  "app-freshness-no-never": false,
  "app-session-a-a": false,
  "app-session-garbage-collector-cron": "*/15 * * * *", // default to every 15 minutes
  "app-session-garbage-collector-limit": 1000,
  "app-session-output-garbage-collector-limit": 10000,
  "app-state-hydration": false,
  "app-state-persistence": false,
  "audit-log-workos-backfill": { enabled: false },
  "audit-log-workos-backfill-interrupt": false,
  "auto-data-labels-for-lines": false,
  "auto-rebuild-custom-images-delay": 250,
  "auto-rebuild-docker-images": false,
  "auto-rebuild-docker-images-cron-job": true,
  "auto-refresh-client-config": { enabled: false },
  "auto-reload-client-on-auth-fail": false,
  "auto-rerun-app-period-seconds": 0,
  "avoid-table-store-multi": true,
  "bigframes-dataframes": false,
  "bigquery-additional-project-ids": false,
  "bigquery-oauth": false,
  "bring-your-own-repo-ui": false,
  "bulk-deactivate-users-by-domain": false,
  "cast-string-columns-for-filters": true,
  "cell-debugger": false,
  "cell-only-screenshot": false,
  "chain-sql-engine": "legacy",
  "chart-debugger": false,
  "chart-reference-lines": false,
  "chart-v1": false,
  "chart-visual-filtering-migration-v2": false,
  "chart-warnings": false,
  "check-kernel-limits": true,
  "client-devtools": DEFAULT_CLIENT_DEVTOOLS_FLAG_CONFIG,
  "component-references-v-2": false,
  "component-upgrade-notifications": false,
  "compute-heartbeat-send-to-segment-enabled": false,
  "compute-heartbeat-sync-to-stripe-cron-schedule": "*/5 * * * *", // default to every 5 minutes
  "compute-heartbeat-sync-to-stripe-mode": "test",
  "compute-heartbeat-sync-to-stripe-status": "JOB_DISABLED",
  "consolidated-kernel-initialization": false,
  "cp-diff-tracking-cron-schedule": "*/1 * * * *",
  "cp-diff-tracking-delay-threshold-ms": 100,
  "cp-diff-tracking-enabled": false,
  "cp-kernel-management": DefaultKernelManagementCPMigrationState,
  "cryptominer-regex": "",
  "custom-kernels-new-auto-rebuild-logic": false,
  "custom-kernels-ui": false,
  "custom-kernels-ui-v2": false,
  "data-conn-diff-schema-update": false,
  "data-conn-diff-schema-update-threshold": 0.1,
  "data-conn-insert-chunk-size": 1000,
  "data-conn-sleep-times": { sleepEvery: 10000, sleepForMs: 10 },
  "data-conn-tree-schema": false,
  "data-connection-db2-setup": false,
  "data-connection-types-to-limit": {},
  "data-curation-tab": false,
  "data-retention-available": false,
  "data-retention-enable-outputs": false,
  "data-retention-enable-query-cache": false,
  "data-source-schema-arrow": false,
  "database-backfill-cron": "",
  "database-backfill-queries": DEFAULT_BACKFILL_CONFIG,
  "database-query-permissions": false,
  "databricks-jdbc-upgrade": false,
  "databricks-oauth": false,
  "dataservice-execution-events": false,
  "db-prompt-templates": false,
  "dbt-data-conn-sleep-times": { sleepEvery: 10000, sleepForMs: 10 },
  "dbt-disallow-proxy-server": false,
  "dbt-fetch-batch-size": 500,
  "dbt-timeout-metadata": 5000,
  "dc-schema-filter-ui": false,
  "deadlock-detector-timeout-s": 60,
  "debug-gh-sync": false,
  "debug-publish-preview": false,
  "declutter-data-browser": false,
  "default-display-table": false,
  "delete-app-session-api-client-on-kill": false,
  "disable-ao-garbage-collector": false,
  "disable-app-session-garbage-queue": false,
  "disable-chat-support-for-company-closure": {
    available: true,
    cta: "",
    message: "",
  },
  "disable-execution-event-api": false,
  "disable-execution-event-writes": false,
  "disable-hex-garbage-collector": false,
  "disable-hex-version-default-app-session-garbage-collector": false,
  "disable-large-pivot-csv": false,
  "disable-prerun-app": false,
  "disable-publish-column-profiling": false,
  "disable-table-deletion-queue": false,
  "disable-workspace-sharing": false,
  "display-table-empty-state": false,
  // This is not the default on LD, but if we fail to pull this flag
  // we want to err on the side of _not_ accidentally charging customers.
  "do-not-charge-for-kernels-created-by-hex": true,
  "duckdb-charts": false,
  "email-denials": false,
  "embedding-v0": false,
  "emit-dbt-data-size-metric": false,
  "emit-schema-size-metric": false,
  "enable-ao-partial-auth-filtering": false,
  "enable-csv-exports-scheduled-runs": false,
  "enable-delete-pods-from-wiz-issues": false,
  "enable-oauth-schema-auth": false,
  "enable-quick-search": true,
  "enable-reindex-schema": false,
  "enable-run-project-with-trace": false,
  "enable-schema-refresh-download-grants": false,
  "enable-shadow-kernel-executions": false,
  "enable-sql-completion-logs": false,
  "enable-write-schema-to-es": false,
  "enforce-compute-spend-limits": false,
  "es-bulk-delete-chunk-size": 300,
  "es-bulk-timeout-schema-refresh": "1m",
  "es-bulk-write-chunk-size": 100,
  "es-delete-all-datasources-delay-ms": 10000,
  "es-delete-by-query-timeout": "1m",
  "es-index-config": {
    datasourceSchemas: {
      backfillIndexes: ["datasource_schemas-v0"],
      readerIndex: "datasource_schemas-v0",
      writerIndexes: ["datasource_schemas-v0"],
    },
    projects: {
      backfillIndexes: ["projects"],
      readerIndex: "projects",
      writerIndexes: ["projects"],
    },
  },
  "es-index-reader-config": {
    datasourceSchemas: "datasource_schemas-v0",
    projects: "projects",
  },
  "es-reindex-config": {
    initialIdOffset: {},
  },
  "es-sync-batch-size": 250,
  "es-sync-hex-chunk-size": 10,
  "es-sync-hex-job-limit": 100,
  "experimentation-test": false,
  "explore-agent-response": false,
  "explore-cancel-button": false,
  "explore-cell-output": false,
  "explore-debug-outputs": false,
  "explore-large-data-warning": 0,
  "explore-suggested-filters": false,
  "explore-whnc-dataframe": false,
  "explorer-role": false,
  "explorer-role-can-view-change": false,
  "explorer-role-visible": false,
  "external-file-integrations": false,
  "facet-chart": false,
  "force-redefinition-edges": false,
  "fstr-timeout-ms": 2000,
  "git-package-import-v2": false,
  "grant-access-for-project-from-slack": false,
  "graphview-update": false,
  "hard-rowlimit": false,
  "hashed-bearer-tokens": false,
  "hex-to-es-sync-mode": HexToESSyncMode.DISABLED,
  "hide-preview-table": false,
  "hybrid-execution": false,
  "hybrid-vega-renderer": false,
  "hydrate-explore-cell": false,
  "idle-kernel-expiry-settings": defaultIdleKernelExpirySettings,
  "in-app-announcements": [],
  "in-app-releases": { on: false },
  "in-app-whats-new": { on: false },
  "indirect-query-event-aggregation": false,
  "intercept-browser-find": false,
  "internal-only-hex-sl-ingest": false,
  "internal-test-collection-queue": DEFAULT_INTERNAL_TEST_COLLECTION_QUEUE,
  "kernel-lifetime-stats": false,
  "kernel-metric-logger": true,
  "kernel-metric-logger-code": true,
  "lance_db-sync-limiter-settings": defaultLanceSyncQueueLimiterSettings,
  "lance-db-admin-only-backfill-ui": false,
  "lance-db-asset-garbage-collector": false,
  "lance-db-asset-garbage-collector-limit": 10,
  "lance-db-asset-garbage-collector-queue-frequency-ms": 60 * 1000, // 1 minute
  "lance-db-data-conn-backfill-data-conn-to-skip": [],
  "lance-db-data-conn-backfill-frequency-ms": 5 * 60 * 1000, // 5 minutes
  "lance-db-data-conn-backfill-limit": 0,
  "lance-db-data-conn-backfill-retry-limit": 1,
  "lance-db-data-conn-errored-actions-limit": 0,
  "lance-db-data-conn-stuck-in-backfilling-limit": 10,
  "lance-db-entity-promotion-limit": 0,
  "lance-db-hex-backfill": false,
  "lance-db-hex-backfill-excluded-orgs": [],
  "lance-db-hex-backfill-frequency-ms": 5 * 60 * 1000, // 5 minutes
  "lance-db-hex-backfill-limit": 0,
  "lance-db-hex-errored-actions-limit": 0,
  "lance-db-hex-stuck-in-backfilling-limit": 0,
  "lance-db-hex-stuck-in-processing-limit": 0,
  "lance-db-long-running-action-threshold-hours": 12,
  "lance-db-sync-batch-lance-actions-queue-interval-ms": 5 * 60 * 1000, // 5 minutes
  "lance-db-sync-batch-size": 50000,
  "lance-db-sync-on-data-conn-refresh": false,
  "lance-db-sync-on-data-manager-column-action": false,
  "lance-db-sync-on-data-manager-data-conn-action": false,
  "lance-db-sync-on-data-manager-database-action": false,
  "lance-db-sync-on-data-manager-schema-action": false,
  "lance-db-sync-on-data-manager-table-action": false,
  "lance-db-table-retrieval-limit": 30,
  "lance-db-table-retrieval-minimum-result-size": 4,
  "lance-db-table-retrieval-timeout-ms": 5000,
  "lance-db-un-indexed-rows-threshold": 10_000,
  "lance-db-use-actions-v2": false,
  "lance-db-use-flow-producer": false,
  "lance-db-where-batch-size": 10000,
  "lance-db-write-timeout-ms": 10000,
  "lazy-load-results": false,
  "lazy-load-results-bq": false,
  "legacy-api-access": false,
  "legacy-git-sync": false,
  "log-dbt-metadata-counts": false,
  "logic-session-timeout-extension": false,
  "magic-35-autofix": false,
  "magic-cell-navigation": false,
  "magic-charts-v2": false,
  "magic-deflection": false,
  "magic-deflection-cell-limit": 0,
  "magic-deflection-embed": false,
  "magic-diff-based-editing-threshold": "off",
  "magic-diff-based-editing-threshold-sql": "off",
  "magic-disable-backtick-stripping": false,
  "magic-edit-sql-formatting-disabled": false,
  "magic-enabled": true,
  "magic-example-table-prompts": 0,
  "magic-example-table-prompts-query-threshold": 0,
  "magic-explore": false,
  "magic-explore-edit": false,
  "magic-explore-edit-logic-view": false,
  "magic-explore-output-contents": false,
  "magic-inline-completion-code": false, // DEPRECATED
  "magic-inline-completion-sql": false, // DEPRECATED
  "magic-lance-project-retrieval-limit": 30,
  "magic-lance-project-retrieval-vector-distance-threshold": 0,
  "magic-max-other-dfs": 25,
  "magic-mention-details": false,
  "magic-run-edits": false,
  "magic-show-tr": false,
  "magic-smoke-test-single-cell": false,
  "magic-suggested-endorsements": false,
  "magic-suggested-prompts-max-attempts": 5,
  "magic-suggested-prompts-pd-events-limit": 100,
  "magic-suggested-prompts-pd-limit": 20,
  "magic-summary": false,
  "magic-table-retrieval-columns-per-table-limit": 1000,
  "magic-table-retrieval-desc-similarity-threshold": 0.9,
  "magic-typeahead-provider-code": MagicTypeAheadProvider.OFF,
  "magic-typeahead-provider-md": MagicTypeAheadProvider.OFF,
  "magic-typeahead-provider-sql": MagicTypeAheadProvider.OFF,
  "magic-update-suggested-prompts-on-schema-refresh": false,
  "manual-work-os-syncs": true,
  "map-dataset-layer": false,
  "map-us-county-data": false,
  "mark-orgs-from-wiz-issues": false,
  "max-attempts-wait-for-es-task-completion": 10,
  "max-dynamic-list-size": 10_000,
  "max-fstr-results": 30,
  "max-kernels-per-user": 25,
  "max-signup-limit": 10,
  "max-untrusted-kernels": -1, // unlimited
  "maybe-embed-hex-delay-ms": 600000, // 10 minutes
  "minerva-executor-ui": false,
  "minerva-selective-execution-cell-types": [],
  "mini-hex-chart": false,
  "mysql-aurora-property": false,
  "new-metricflow-ingest": false,
  "new-semantic-dataset-ui": false,
  "new-share-dialog": false,
  "new-toc": false,
  "no-code-announce-blog-post-url": "https://hex.tech",
  "no-code-announce-dialog": false,
  "no-code-announce-video-url":
    "https://static.hex.site/hex_three-oh-autoplay-720.mov",
  "no-max-signups": false,
  "non-vf-legend": false,
  "oauth-collab-always-expose-outputs": false,
  "oauth-collab-lite": false,
  "oauth-dc-service-account-schema": false,
  "only-add-code-cells-on-first-run": false,
  "open-ai-embedding-batch-size": 1000,
  "open-ai-embedding-concurrency": 10,
  "open-ai-model-override": "none",
  "outline-v2": false,
  "outline-v2-live-toc": false,
  "output-iframe-domain": "blob",
  "pandas-copy-on-write": false,
  "password-users": false,
  "patch-charts": false,
  "preview-mode": false,
  "prewarm-kernels-ux-enablement": false,
  "priority-cell-execution": false,
  "product-launch": false,
  "product-launch-demo-hide-beta-flags": false,
  "prompt-injection-window-size": 10,
  "prune-sql-output-stages": false,
  "public-api-ban-config": { isBanned: false },
  "publish-as-snapshot": false,
  "pylon-chat": false,
  "query-event-queue-enabled": false,
  "query-mode-default-on": false,
  "query-table-in-no-code-cell": false,
  "quick-search-query-config": DEFAULT_QUERY_CONFIG,
  "redis-presence-enabled": true,
  "redis-presence-recording-disabled": false,
  "reduce-csp-after-domain-change": false,
  "reduce-default-logic-idle-timeout-datetime": {
    reduceDefaultTimeoutAfter: new Date(
      Date.now() + 1 * 24 * 60 * 60 * 1000,
    ).toISOString(), // sets default date to 1 day from now
  },
  "refresh-on-graphql-error": false,
  "refresh-version-on-reconnect": false,
  "register-ready-pods": false,
  "reindex-es-batch-size": 500,
  "reindex-es-job-limit": 1000,
  "reindex-schema-rate-limit-duration": 1,
  "reindex-schema-rate-limit-points": 1,
  "resource-sync-to-compute-heartbeat-enabled": false,
  "rudderstack-migration": "OFF",
  "saved-views": false,
  "saved-views-schedules-fe": false,
  "scheduled-run-concurrency": 1,
  "scheduled-run-delay": 60000,
  "scheduler-delayed-job-queue-v2": false,
  "schema-chunk-size": 1000,
  "schema-refresh-data-service-batch-size": 10_000,
  "schema-refresh-download-grants-rate-limit-config": {
    batchSize: 2_000,
    duration: 1,
    points: 1,
  },
  "schema-refresh-download-schema-batch-size": 100,
  "schema-refresh-gc-batch-size": 100,
  "schema-refresh-gc-delay-ms": 1000,
  "schema-refresh-get-orphaned-entities-batch-size": 1000,
  "schema-refresh-get-schema-batch-size": 1000,
  "schema-refresh-interrupt": "NO_INTERRUPT",
  "schema-refresh-max-column-count": 2_000_00,
  "schema-refresh-rate-limit-duration": 1,
  "schema-refresh-rate-limit-points": 1,
  "schema-refresh-update-db-batch-size": 5000,
  "schema-refresh-update-es-batch-size": 5000,
  "schema-refresh-update-lance-batch-size": 5000,
  "schema-refresh-use-get-datasource-entity-endpoints": true,
  "schema-refresh-v3-rate-limiting": DEFAULT_SCHEMA_REFRESH_RATE_LIMIT_CONFIG,
  "screenshot-device-scale-factor": 1,
  "screenshot-extra-timeout": 5000,
  "screenshot-max-height": 15000,
  "selective-minerva-execution": false,
  "semantic-caps-per-entity": DEFAULT_SEMANTIC_CAPS_PER_ENTITY_CONFIG,
  "semantic-ingest-es-rate-limit-duration": {
    duration: 1,
    points: 5,
  },
  "semantic-layer": false,
  "server-open-ai-model-override": "none",
  "show-compute-profile-migration-alerts": false,
  "sidecar-batch-debounce-ms": 0,
  "sidecar-health-monitoring-interval": 30_000,
  "signed-embedding-api-rate-limit": 20,
  "skip-old-sidecar-requests": false,
  "slack-channel-query-limit": 30,
  "slack-integration-v2": false,
  "slack-interactivity": false,
  "smoke-test-cleanup-limit": 2000,
  "sqlfluff-format": false,
  "st-prewarmed-defaults": NoPrewarmedImages,
  "store-embedded-api-calls": false,
  "stream-motherduck-results": true,
  "stripe-check-events-minimum-ms": 0,
  "stripe-local-data": false,
  "stytch-telemetry-v1": false,
  "supernova-a-a": false,
  "svc-aggs": false,
  "svg-chart": false,
  "table-store-hex-versioning-enabled": false,
  "telemetry-enabled": true,
  "telemetry-timeout": 2_000,
  "telemetry-truncate": false,
  "telemetry-use-stytch-cname": false,
  "text-cell-always-stream-mp-updates": false,
  "totals-bar": false,
  "truncate-execution-event": false,
  "typeahead-a-a-experiment": false,
  "unidf-query-mode": false,
  "upgrade-duckdb": false,
  "upgrade-snowpark": false,
  "use-create-database-v2": false,
  "use-custom-kernels-only": false,
  "use-in-house-ck": false,
  "use-new-hv-default-app-session": false,
  "use-only-register-ready-pods": false,
  "viewer-logic-perm-warning": { changelogName: "", dateString: "", on: false },
  "virtualized-diff": false,
  "wait-for-es-task-completion-delay-in-ms": 1_000,
  "websocket-auth-caching": { timeout: null },
  "websocket-degraded-network-config": {
    fastForwardWindowSeconds: 60,
    keepAliveSeconds: 60,
  },
  "workos-backfill-enabled": false,
  "workspace-api-tokens": false,
  "workspace-invite-links": false,
  "zendesk-chat": false,
  ...NewUserFeatureFlagDefaults,
};

type FeatureFlagOverrideFn<T> = ({
  email,
  env,
  orgId,
  stackId,
}: {
  email?: string;
  env?: ProductionEnvironment;
  orgId?: OrgId;
  stackId?: string;
}) => T | undefined;
export type FeatureFlagOverride<T> = T | FeatureFlagOverrideFn<T>;

export type FeatureFlagOverrides = {
  [K in keyof FeatureFlagSet]?: FeatureFlagOverride<FeatureFlagSet[K]>;
};

// When we explicitly disable launch darkly, we include a set of offline
// overrides to manage behavior. This is specifically relevant to running E2E
// tests, where we want to avoid inflating our MAUs in LD but still support
// having multiple conditions under test.
export const LaunchDarklyDisabledOverrides: FeatureFlagOverrides = {
  "additional-base-images": true,
  "additional-data-connection-refresh-logging": false,
  "additional-mariadb-params": true,
  "allow-duplication-for-project": true,
  "allow-public-domain-signups": false,
  "app-session-garbage-collector-cron": "*/15 * * * *", // default to every 15 minutes
  "app-session-garbage-collector-limit": 1000,
  "app-session-output-garbage-collector-limit": 10000,
  "app-state-persistence": true,
  "auto-data-labels-for-lines": false,
  "auto-rebuild-custom-images-delay": 250,
  "auto-rebuild-docker-images": false,
  "auto-rebuild-docker-images-cron-job": true, // This controls if the CRON job itself runs
  "bigframes-dataframes": false,
  "bring-your-own-repo-ui": false,
  "chain-sql-engine": "sqlparse",
  "chart-debugger": true,
  "chart-reference-lines": true,
  "chart-v1": true,
  "chart-warnings": false,
  "compute-heartbeat-send-to-segment-enabled": false,
  "compute-heartbeat-sync-to-stripe-cron-schedule": "*/5 * * * *", // default to every 5 minutes
  "compute-heartbeat-sync-to-stripe-mode": "test",
  "compute-heartbeat-sync-to-stripe-status": "JOB_DISABLED",
  "consolidated-kernel-initialization": true,
  "cp-diff-tracking-cron-schedule": "*/1 * * * *",
  "cp-diff-tracking-delay-threshold-ms": 100,
  "cp-diff-tracking-enabled": false,
  "cp-kernel-management": DefaultKernelManagementCPMigrationState,
  "custom-kernels-ui": false,
  "custom-kernels-ui-v2": false,
  "data-conn-diff-schema-update": false,
  "data-conn-diff-schema-update-threshold": 0.1,
  "data-conn-insert-chunk-size": 1000,
  "data-conn-sleep-times": { sleepEvery: 10000, sleepForMs: 10 },
  "data-conn-tree-schema": false,
  "data-curation-tab": false,
  "database-query-permissions": true,
  "databricks-jdbc-upgrade": true,
  "db-prompt-templates": false,
  "dbt-data-conn-sleep-times": { sleepEvery: 10000, sleepForMs: 10 },
  "dc-schema-filter-ui": false,
  "debug-gh-sync": true,
  "debug-publish-preview": false,
  "delete-app-session-api-client-on-kill": false,
  "disable-ao-garbage-collector": false,
  "disable-app-session-garbage-queue": false,
  "disable-execution-event-api": false,
  "disable-hex-garbage-collector": false,
  "disable-hex-version-default-app-session-garbage-collector": false,
  "disable-table-deletion-queue": false,
  "disable-workspace-sharing": false,
  "duckdb-charts": false,
  "emit-dbt-data-size-metric": false,
  "emit-schema-size-metric": false,
  "enable-ao-partial-auth-filtering": false,
  "enable-delete-pods-from-wiz-issues": false,
  "enable-quick-search": true,
  "enable-reindex-schema": false,
  "enable-run-project-with-trace": false,
  "enable-schema-refresh-download-grants": false,
  "enable-shadow-kernel-executions": false,
  "enable-sql-completion-logs": false,
  "enable-write-schema-to-es": false,
  "enforce-compute-spend-limits": false,
  "es-bulk-delete-chunk-size": 300,
  "es-bulk-timeout-schema-refresh": "1m",
  "es-bulk-write-chunk-size": 100,
  "es-delete-all-datasources-delay-ms": 10000,
  "es-delete-by-query-timeout": "1m",
  "es-index-config": {
    datasourceSchemas: {
      backfillIndexes: ["datasource_schemas-v0"],
      readerIndex: "datasource_schemas-v0",
      writerIndexes: ["datasource_schemas-v0"],
    },
    projects: {
      backfillIndexes: ["projects"],
      readerIndex: "projects",
      writerIndexes: ["projects"],
    },
  },
  "es-index-reader-config": {
    datasourceSchemas: "datasource_schemas-v0",
    projects: "projects",
  },
  "es-reindex-config": {
    initialIdOffset: {},
  },
  "es-sync-hex-chunk-size": 10,
  "es-sync-hex-job-limit": 100,
  "explore-agent-response": false,
  "explore-cancel-button": false,
  "explore-large-data-warning": 0,
  "explore-suggested-filters": false,
  "facet-chart": true,
  "force-redefinition-edges": true,
  "fstr-timeout-ms": 2000,
  "git-package-import-v2": false,
  "graphview-update": false,
  "hex-to-es-sync-mode": HexToESSyncMode.DISABLED,
  "hybrid-vega-renderer": true,
  "hydrate-explore-cell": true,
  "intercept-browser-find": false,
  "internal-test-collection-queue": DEFAULT_INTERNAL_TEST_COLLECTION_QUEUE,
  "kernel-lifetime-stats": false,
  "lance_db-sync-limiter-settings": defaultLanceSyncQueueLimiterSettings,
  "lance-db-admin-only-backfill-ui": false,
  "lance-db-asset-garbage-collector": false,
  "lance-db-asset-garbage-collector-limit": 10,
  "lance-db-asset-garbage-collector-queue-frequency-ms": 60 * 1000, // 1 minute
  "lance-db-data-conn-backfill-data-conn-to-skip": [],
  "lance-db-data-conn-backfill-frequency-ms": 5 * 60 * 1000, // 5 minutes
  "lance-db-data-conn-backfill-limit": 0,
  "lance-db-data-conn-backfill-retry-limit": 1,
  "lance-db-data-conn-errored-actions-limit": 0,
  "lance-db-data-conn-stuck-in-backfilling-limit": 10,
  "lance-db-hex-backfill": false,
  "lance-db-hex-backfill-excluded-orgs": [],
  "lance-db-hex-backfill-frequency-ms": 5 * 60 * 1000, // 5 minutes
  "lance-db-hex-backfill-limit": 0,
  "lance-db-hex-errored-actions-limit": 0,
  "lance-db-hex-stuck-in-backfilling-limit": 0,
  "lance-db-hex-stuck-in-processing-limit": 0,
  "lance-db-long-running-action-threshold-hours": 12,
  "lance-db-sync-batch-lance-actions-queue-interval-ms": 5000,
  "lance-db-sync-batch-size": 50000,
  "lance-db-sync-on-data-conn-refresh": false,
  "lance-db-sync-on-data-manager-column-action": false,
  "lance-db-sync-on-data-manager-data-conn-action": false,
  "lance-db-sync-on-data-manager-database-action": false,
  "lance-db-sync-on-data-manager-schema-action": false,
  "lance-db-sync-on-data-manager-table-action": false,
  "lance-db-table-retrieval-limit": 30,
  "lance-db-table-retrieval-minimum-result-size": 4,
  "lance-db-table-retrieval-timeout-ms": 5000,
  "lance-db-un-indexed-rows-threshold": 10_000,
  "lance-db-use-flow-producer": false,
  "lance-db-where-batch-size": 10000,
  "lance-db-write-timeout-ms": 10000,
  "legacy-git-sync": false,
  "magic-35-autofix": false,
  "magic-cell-navigation": false,
  "magic-charts-v2": false,
  "magic-deflection": false,
  "magic-deflection-cell-limit": 0,
  "magic-deflection-embed": false,
  "magic-diff-based-editing-threshold": "off",
  "magic-diff-based-editing-threshold-sql": "off",
  "magic-disable-backtick-stripping": false,
  "magic-edit-sql-formatting-disabled": false,
  "magic-enabled": ({ email }) => {
    if (email && email.includes("magic-enabled+false")) {
      return false;
    }
    return true;
  },
  "magic-example-table-prompts": 0,
  "magic-example-table-prompts-query-threshold": 0,
  "magic-explore": false,
  "magic-explore-edit": false,
  "magic-explore-edit-logic-view": false,
  "magic-explore-output-contents": false,
  "magic-inline-completion-code": false, // DEPRECATED
  "magic-inline-completion-sql": false, // DEPRECATED
  "magic-lance-project-retrieval-limit": 30,
  "magic-lance-project-retrieval-vector-distance-threshold": 0,
  "magic-max-other-dfs": 25,
  "magic-mention-details": false,
  "magic-run-edits": false,
  "magic-show-tr": false,
  "magic-smoke-test-single-cell": false,
  "magic-suggested-endorsements": false,
  "magic-suggested-prompts-max-attempts": 5,
  "magic-suggested-prompts-pd-events-limit": 100,
  "magic-suggested-prompts-pd-limit": 20,
  "magic-summary": false,
  "magic-table-retrieval-columns-per-table-limit": 1000,
  "magic-table-retrieval-desc-similarity-threshold": 0.9,
  "magic-typeahead-provider-code": MagicTypeAheadProvider.OFF,
  "magic-typeahead-provider-md": MagicTypeAheadProvider.OFF,
  "magic-typeahead-provider-sql": MagicTypeAheadProvider.OFF,
  "magic-update-suggested-prompts-on-schema-refresh": false,
  "manual-work-os-syncs": true,
  "map-dataset-layer": true,
  "mark-orgs-from-wiz-issues": false,
  "max-attempts-wait-for-es-task-completion": 10,
  "max-fstr-results": 30,
  "max-signup-limit": 10,
  "maybe-embed-hex-delay-ms": 600000, // 10 minutes
  "mini-hex-chart": false,
  "mysql-aurora-property": true,
  "new-toc": false,
  "no-max-signups": false,
  "non-vf-legend": false,
  "only-add-code-cells-on-first-run": true,
  "open-ai-embedding-batch-size": 1000,
  "open-ai-embedding-concurrency": 10,
  "pandas-copy-on-write": true,
  "password-users": false,
  "patch-charts": false,
  "preview-mode": false,
  "prewarm-kernels-ux-enablement": false,
  "priority-cell-execution": true,
  "prompt-injection-window-size": 10,
  "publish-as-snapshot": false,
  "query-mode-default-on": false,
  "quick-search-query-config": DEFAULT_QUERY_CONFIG,
  "reduce-csp-after-domain-change": false,
  "refresh-on-graphql-error": false,
  "refresh-version-on-reconnect": false,
  "register-ready-pods": false,
  "reindex-es-batch-size": 500,
  "reindex-es-job-limit": 1000,
  "reindex-schema-rate-limit-duration": 1,
  "reindex-schema-rate-limit-points": 1,
  "resource-sync-to-compute-heartbeat-enabled": false,
  "saved-views": false,
  "schema-chunk-size": 1000,
  "schema-refresh-data-service-batch-size": 10_000,
  "schema-refresh-download-grants-rate-limit-config": {
    batchSize: 2_000,
    duration: 1,
    points: 1,
  },
  "schema-refresh-download-schema-batch-size": 100,
  "schema-refresh-gc-batch-size": 100,
  "schema-refresh-gc-delay-ms": 1000,
  "schema-refresh-get-orphaned-entities-batch-size": 1000,
  "schema-refresh-get-schema-batch-size": 1000,
  "schema-refresh-interrupt": "NO_INTERRUPT",
  "schema-refresh-max-column-count": 2_000_000,
  "schema-refresh-rate-limit-duration": 1,
  "schema-refresh-rate-limit-points": 1,
  "schema-refresh-update-db-batch-size": 5000,
  "schema-refresh-update-es-batch-size": 5000,
  "schema-refresh-update-lance-batch-size": 5000,
  "schema-refresh-use-get-datasource-entity-endpoints": true,
  "screenshot-device-scale-factor": 1,
  "screenshot-extra-timeout": 5000,
  "screenshot-max-height": 15000,
  "semantic-caps-per-entity": DEFAULT_SEMANTIC_CAPS_PER_ENTITY_CONFIG,
  "skip-old-sidecar-requests": false,
  "slack-channel-query-limit": 30,
  "sqlfluff-format": false,
  "st-prewarmed-defaults": NoPrewarmedImages,
  "svg-chart": true,
  // By default, enabled, but for purposes of shutting off LD we'll ALSO shut
  // this off.
  "telemetry-enabled": false,
  "telemetry-timeout": 2_000,
  "typeahead-a-a-experiment": false,
  "use-create-database-v2": false,
  "use-in-house-ck": false,
  "use-only-register-ready-pods": false,
  "viewer-logic-perm-warning": { changelogName: "", dateString: "", on: false },
  "virtualized-diff": false,
  "wait-for-es-task-completion-delay-in-ms": 1_000,
  "workspace-api-tokens": false,
};

export const AppFeatureFlagRuntype = Union(
  ...(typedObjectKeys(DEFAULT_FLAGS).reduce((acc, flag) => {
    if (!isNewUserFeature(flag)) {
      acc.push(Literal(flag));
    }
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, [] as any[]) as any),
) as Runtype<AppFeatureFlag>;

/**
 *
 * @param flagValue the value of the LD flag
 * @param date the date to compare against the flag value
 * @returns true ("enabled") if date >= flagValue, false ("disabled") otherwise
   (or if flag value is explicitly set to "DISABLED") * Will throw error if
   unable to parse date from LD flag value.
 */
export const evaluateMinDateFlag = (
  flagValue: string | undefined,
  date: Date,
): boolean => {
  if (flagValue == null || flagValue === "DISABLED") {
    return false;
  }

  const minDate = new Date(flagValue);
  if (isNaN(minDate.getTime())) {
    throw new Error("Invalid date");
  }

  return date.getTime() >= minDate.getTime();
};

type AppFeatureFlagSet = Omit<FeatureFlagSet, FlaggedNewUserFeature>;

// There are certain flags that correspond to "new user" features, which should
// only be evaluated when a user first logs into Hex. We are generating this
// type that excludes those flags values to ensure that those flags are not
// evaluated outside of first login.
export type AppFeatureFlag = keyof AppFeatureFlagSet;

// These are flags exported to the python kernel and accessible from the Python
// kernel.
export const kernelFeatureFlags = [
  "disable-large-pivot-csv",
] as const satisfies (keyof FeatureFlagSet)[];
